@import "../../../styles/variable.module.scss";
.browseCatSec {
  .h3 {
    margin-bottom: 40px;
    @include medium_device {
      font-size: 32px;
      margin-bottom: 12px;
    }
    @include mobile_device {
      font-size: 24px;
    }
  }

  .browseCat {
    background: var(--block-color);
    border: 1px solid var(--borderSec-color);
    border-radius: 16px;
    margin-top: 24px;
    padding: 8px;
    display: block;
    position: relative;
    overflow: hidden;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    &:hover {
      -webkit-box-shadow: 0 0 0 2px var(--border-color) inset !important;
      box-shadow: 0 0 0 2px var(--border-color) inset !important;
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px);
    }
    &:before {
      content: "";
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transform-origin: 0 50%;
      transform-origin: 0 50%;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
    }
  
    @include large_device {
      margin-top: 20px;
    }
    .category_img {
      flex: 1 0 auto;
      max-width: 56px;
      img {
        border-radius: 50px;
      }
    }
    .category_text {
      @include fontWeight(font-weight, 500, 700);
      font-size: 16px;
      line-height: 24px;
      color: var(--desc-font);
      position: relative;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
    }
    .icon {
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
    }
  }
}